/**
 * This contains exported code that works with question data (JSONQuestion data more specifically).
 *
 * Because we use this for inputs within a question tree and just a generic form (e.g., signup form), I put this in a
 * separate file than questionTree.ts. In other words if it's something we'll use for a non-tree form and questions that
 * are part of a question tree, put it here.
 */

import {topicPermissionFromEditMode} from "@/composables/2FA";
import {getConnectionDataLite} from "@/composables/connections";
import type {ConnectionProfileData} from "@/composables/connections/types";
import {formatDate, formatDateTime} from "@/composables/dates";
import {getStore} from "@/composables/get.store";
import {translate} from "@/composables/i18n";
import {getListCache} from "@/composables/lists";
import {formatUsers, getOptionsAsync, getUserInputOptions} from "@/composables/questions/getOptions";
import type {CertifyAnswer, SectionOption, SelectAnswer, UploadFile, UserOption} from "@/composables/questions/types";
import {EditRules, readOnlyInputTypes} from "@/composables/questions/types";
import {getCurrentUser, getUserActiveEntityId, getUserDateFormat, getUserDateTimeFormat} from "@/composables/vuex";
// @ts-ignore
import postalMasks from "@/data/postal-code-format.json";
import moment from "moment";
import type {APIConnection, ConnectionDataLite} from "pg-isomorphic/api/connection";
import type {ConnectionDocument} from "pg-isomorphic/api/search";
import type {ListUser, UserAvatar} from "pg-isomorphic/api/user";
import {Address, Connection, Internal, QuestionType, SpecialUser, Stage, TableColumnType} from "pg-isomorphic/enums";
import {check, Permission} from "pg-isomorphic/permissions";
import {filter, findNearestNeighbor} from "pg-isomorphic/profile";
import type {JSONObject, JSONQuestion} from "pg-isomorphic/utils";
import {isEmptyOrUndefined} from "pg-isomorphic/utils";
import {coerceToArray} from "pg-isomorphic/utils";
import {indexBy, is, pathOr, prop} from "ramda";
import type {Ref} from "vue";
import {getUsers} from "@/composables/user";
import {getTextFromLocaleObj} from "pg-isomorphic/utils/locale";

export function translateFileToMarkup(answer: UploadFile[]) {
  if (is(Array, answer) && answer.length > 0) {
    return answer
      .map((file) => {
        if (!file) {
          // no idea how this happens
          return "";
        }
        const docusignStatus = pathOr("", ["docusignDetails", "status"], file);
        let docusignStatusAppendHtml = "";
        if (docusignStatus) {
          docusignStatusAppendHtml = `<br />${translate("questions.upload.docusign.status")}: ${docusignStatus}`;
        }
        if (file.mimeType === "url") {
          return `<a href="//${file.fileId}" target="_new">${
            file.filename || file.fileName
          }</a>${docusignStatusAppendHtml}`;
        } else {
          return `<a href="/api/files/${file.fileId}?thumbnail=${file.hasThumbnail}" target="_new">${
            file.filename || file.fileName
          }</a>${docusignStatusAppendHtml}`;
        }
      })
      .join("<br />");
  } else {
    return "";
  }
}

export function translateFilesWithLinks(answer: UploadFile[]) {
  if (is(Array, answer)) {
    return answer.map((fileData) => {
      if (fileData.mimeType === "url") {
        return {
          name: fileData.fileName || fileData.filename,
          url: fileData.fileName,
        };
      } else {
        return {
          name: fileData.fileName || fileData.filename,
          url: `/api/files/${fileData.fileId}`,
        };
      }
    });
  } else {
    return "";
  }
}

export function formatImageUrl(image: string) {
  return image ? `/api/files/${image}?thumbnail=true` : "";
}

export function imgUrl(logo: UploadFile[]): string | null {
  if (!logo || logo.length < 1 || !logo[0].fileId) {
    return null;
  }
  return `/api/files/${logo[0].fileId}?thumbnail=true`;
}

export function isConnectionVisible(item: ConnectionDocument): boolean {
  if (!item.connection) {
    return false;
  } else if (item.status === Stage.INVITE && item.connection.requestingEntityId !== getUserActiveEntityId()) {
    return false;
  } else {
    return true;
  }
}

export interface ConnectionDocumentDateData
  extends Pick<ConnectionDocument, "status" | "transitionDate" | Internal.PROFILE_UPDATE_DATE> {
  connection: Pick<APIConnection, "lastInviteDate">;
}

export function connectionDateISO(item: ConnectionDocumentDateData, fallbackToProfileUpdateData = false): string {
  if (item.status === Stage.ACCEPT && item.connection && item.connection.lastInviteDate) {
    return item.connection.lastInviteDate;
  } else if (item.transitionDate) {
    return item.transitionDate as unknown as string;
  } else if (fallbackToProfileUpdateData && item[Internal.PROFILE_UPDATE_DATE]) {
    return item[Internal.PROFILE_UPDATE_DATE];
  } else {
    return "";
  }
}

export function connectionDate(item: ConnectionDocumentDateData, fallbackToProfileUpdateData = false): string {
  const date = connectionDateISO(item, fallbackToProfileUpdateData);
  return date ? formatDate(date) : "";
}

export function formatCampaignConnectionStatus(item: ConnectionDocument): string {
  if (!item.status) {
    return translate("connection.undefined");
  } else if (item.status === Stage.DISCONNECTED && item[Connection.SUPPLIER_DISCONNECT_REASON]) {
    return item[Connection.SUPPLIER_DISCONNECT_REASON];
  } else if (item.status === Stage.INVITE && !isConnectionVisible(item)) {
    return translate("connection.registration_in_progress");
  } else {
    return translate(`connection.${item.status}`);
  }
}

/**
 * Translates a boolean value to a yes or no string (localized)
 * @param value
 */
export function translateBoolean(value: boolean): string {
  if (value) {
    return translate("actions.yes") as string;
  } else {
    return translate("actions.no") as string;
  }
}

/**
 * Takes option, answer, etc, and will give you the appropriate value for that option back
 * @param options
 * @param answer
 * @param questionData
 * @param trackByName
 */
export function translateSingleValueByField(
  options: SectionOption[],
  answer: SelectAnswer,
  questionData: JSONQuestion,
  trackByName = "label",
): string {
  if (!options) {
    return coerceToArray(answer).join(", ");
  }

  for (const option of options) {
    if (option.value === answer) {
      return option[trackByName];
    }
  }

  if (questionData?.optionsAnswerKey && answer) {
    return translate("questions.missing_reference") as string;
  }

  return coerceToArray(answer).join(", ");
}

export function translateUserFromAvatar(avatar: UserAvatar[], myLogo: string) {
  const userObjectFromAvatar = (userAvatar) => {
    let userObject = null;
    if (userAvatar._id === SpecialUser.AUTO_APPROVED) {
      userObject = {
        value: userAvatar._id,
        label: translate("review.auto_approved"),
        name: translate("review.auto_approved"),
        image: myLogo,
        shape: "square",
      };
    } else {
      userObject = {
        value: userAvatar._id,
        label: userAvatar.displayName,
        name: userAvatar.displayName,
        image: userAvatar.imageThumbnailUrl || "",
      };
    }
    return userObject;
  };
  let val = null;
  if (avatar) {
    if (Array.isArray(avatar)) {
      val = [];
      for (const user of avatar) {
        val.push(userObjectFromAvatar(user));
      }
    } else {
      val = userObjectFromAvatar(avatar);
    }
  }
  return val;
}

/**
 * Translates a question with options that has can only have a single value selected
 * @param options
 * @param answer
 * @param questionData
 * @param trackByName
 */
export function translateSingleValue(
  options: SectionOption[],
  answer: SelectAnswer,
  questionData?: JSONQuestion,
  trackByName = "label",
) {
  return translateSingleValueByField(options, answer, questionData, trackByName);
}

/**
 * Translates a question with options that has can have multiple values selected
 * @param options
 * @param answer
 * @param questionData
 * @param trackByName
 */
export function translateMultiValue(
  options: SectionOption[],
  answer: SelectAnswer | SelectAnswer[],
  questionData: JSONQuestion,
  trackByName = "label",
): string {
  if (!options || answer === undefined || answer === null) {
    return coerceToArray(answer).join(", ");
  }
  const transValues = coerceToArray(answer).map((val) => translateSingleValue(options, val, questionData, trackByName));
  return transValues.join(", ");
}

// I don't think there's an Address type anywhere?
export function formatAddress(val: any): string {
  if (!val[Address.Line1]) {
    return "";
  }

  const regionState = val[Address.RegionState].split("-")?.[1] || val[Address.RegionState];

  return [
    `${val[Address.Line1]}`,
    `${val[Address.Line2]}`,
    `${val[Address.City]}, ${regionState} ${val[Address.PostalCode]}`,
    `${val[Address.Country]}`,
  ]
    .filter((v) => !!v && v !== "undefined")
    .map((str) => str.trim())
    .join("\n");
}

/**
 * Given a question data object and value it will give you a string representation of the value
 * @param value
 * @param questionData
 * @param connectionData
 */
export async function formatAnswer(
  value: any,
  questionData: JSONQuestion,
  connectionData?: ConnectionProfileData,
): Promise<string | UserOption[]> {
  let formatted = "---";
  if (!value) {
    return "";
  }

  // Vuex user state data
  const store = getStore();
  const userLang = pathOr("en", ["state", "user", "localeSettings", "language"], store);
  const lists = getListCache(userLang);

  formatted = value;
  const listKey: string | undefined = questionData.list?.key;

  switch (questionData.type) {
    case QuestionType.SELECT:
      if (listKey && lists[listKey]) {
        formatted = translateSingleValue(lists[listKey], value, questionData);
      } else {
        formatted = translateSingleValue(
          (await getOptionsAsync(questionData, getConnectionDataLite(connectionData))) as any,
          value,
          questionData,
        );
      }
      break;
    case QuestionType.USER_SELECT:
      if (value === SpecialUser.AUTO_APPROVED) {
        formatted = translate("review.auto_approved");
      } else {
        const options = await getUserInputOptions({
          questionData,
          suppressEmailAppend: true,
          defaultUserIds: !Array.isArray(value) ? [value] : value,
        });
        formatted = Array.isArray(value)
          ? translateMultiValue(options, value, questionData)
          : translateSingleValue(options, value, questionData);
      }
      break;
    case QuestionType.TOPIC_ASSIGN: {
      if (!Array.isArray(value)) {
        value = coerceToArray(value);
      }
      let users = await getUsers({userIds: value});
      if (connectionData?.connection?._id) {
        users = users.concat(
          await store.dispatch("users/getConnectionUsers", {
            connectionId: connectionData.connection._id,
            options: {userIds: value},
          }),
        );
      }
      const userOptions = formatUsers(users as ListUser[]) as UserOption[];
      return userOptions;
    }
    case QuestionType.ROLE_TABLE:
    case QuestionType.CONTACT: {
      if (!Array.isArray(value)) {
        value = coerceToArray(value);
      }

      let users = await store.dispatch("users/getUsers", {options: {limit: 20}});
      if (connectionData?.connection?._id) {
        users = users.concat(
          await store.dispatch("users/getConnectionUsers", {
            connectionId: connectionData.connection._id,
            options: {limit: 20},
          }),
        );
      }

      if (value.length > 0) {
        const valueUserIds = value.filter((item) => item).map((item) => item?.userId);
        const valueUsers = await getUsers({userIds: valueUserIds});
        users = users.concat(valueUsers);
      }

      const userOptions = formatUsers(users as ListUser[]) as UserOption[];
      const formattedUserOptions: UserOption[] = [];
      for (const item of value) {
        if (item) {
          const userId = questionData.type === QuestionType.ROLE_TABLE ? item : item.userId;
          const foundUser = userOptions.find((option) => option.value === userId);

          if (foundUser) {
            formattedUserOptions.push(foundUser);
          }
        }
      }

      return formattedUserOptions;

      break;
    }
    case QuestionType.BOOLEAN:
      formatted = translateBoolean(value);
      break;
    case QuestionType.CERTIFY: {
      const certifyValue: CertifyAnswer = value;
      if (isEmptyOrUndefined(certifyValue.certified)) {
        return "";
      }
      formatted = certifyValue.certified ? translateBoolean(true) : translateBoolean(false);
      break;
    }
    case QuestionType.IMAGE: {
      const imageId = pathOr("", ["0", "fileId"], value);
      formatted = `<img src="${formatImageUrl(imageId)}"  alt=""/>`;
      break;
    }
    case QuestionType.CHECKBOX:
    case QuestionType.CHECKBOX_OTHER:
    case QuestionType.CHECKLIST_VIEW:
      formatted = translateMultiValue(
        (await getOptionsAsync(questionData, getConnectionDataLite(connectionData))) as any,
        value,
        questionData,
      );
      break;
    case QuestionType.RADIO:
    case QuestionType.RADIO_OTHER:
      formatted = translateSingleValue(
        (await getOptionsAsync(questionData, getConnectionDataLite(connectionData))) as any,
        value,
        questionData,
      );
      break;
    case QuestionType.TAGS:
      formatted = coerceToArray(value).join(", ");
      break;
    case QuestionType.FILE:
    case QuestionType.FILE_OR_URL:
    case QuestionType.URL:
      formatted = translateFileToMarkup(value);
      break;
    case QuestionType.DATE: {
      formatted = moment(value).format(getUserDateFormat());
      break;
    }
    case QuestionType.DATE_TIME: {
      formatted = moment(value).format(getUserDateTimeFormat());
      break;
    }
    case QuestionType.ADDRESS:
      formatted = formatAddress(value);
      break;
    case QuestionType.ROUTING:
      if (value?.routing) {
        return value.routing;
      } else if (typeof value === "string") {
        // We have answer key Bank_SWIFT used by multiple questions of type text and routing.
        // Sometimes it's saved as text.
        return value;
      } else {
        return "";
      }
  }

  return store.getUserLocalizedText(formatted);
}

export function isReadOnly(questionData: JSONQuestion, editRules: EditRules): boolean {
  const user = getStore().state.user;
  return Boolean(
    (editRules !== EditRules.CAN_ONLY_EDIT_INTERNAL_USE ||
      !!questionData.internalUse ||
      !!questionData.counterpartyCanEditAnswer) &&
      !isLabelInputType(questionData) &&
      check(Permission.WRITE_ANSWERS, user) &&
      questionData.readOnly,
  );
}

export function isLabelInputType(questionData: JSONQuestion) {
  return readOnlyInputTypes.indexOf(questionData.type) !== -1;
}

export function is2FAUnlocked(topicKey: string, editMode: boolean): boolean {
  const verified = getStore().state.twoFactor.verifiedForQuestions;
  const verifiedTopics = getStore().state.twoFactor.verifiedTopics;
  const target = topicPermissionFromEditMode(editMode);
  return !!(verified && verifiedTopics[topicKey] >= target);
}

export function is2FAUnlockedForApprovals(_topicKey: string): boolean {
  const verified = getStore().state.twoFactor.verifiedForApprovals;
  return !!verified;
}

export function is2FAEnabled(questionData: JSONQuestion): boolean {
  const isObscured = questionData.type === QuestionType.OBSCURED;
  return questionData.secured && !isObscured;
}

export function batchDownloadFiles(fileIds: string[], entityName?: string) {
  const fileIdQueryStr = fileIds.map((fileId) => `fileIds[]=${fileId}`).join("&");
  const entityNameQueryStr = entityName ? `&entityName=${entityName}` : "";
  window.open(`/api/files/batch?${fileIdQueryStr}${entityNameQueryStr}`, "_blank");
}

export class ProfileDebugging {
  constructor(public readonly connectionData: Ref<ConnectionProfileData>, public readonly questionTree: Ref) {}

  public questionsByKey(key: string): JSONQuestion[] {
    return filter((q) => q.key === key, this.questionTree.value.initializedQuestionData);
  }

  public match(one?: string, two?: string): boolean {
    if (!one || !two) {
      return false;
    }
    return one.match(new RegExp(two, "i")) !== null;
  }

  public searchQuestions(query: string): JSONQuestion[] {
    return filter(
      (q) => this.match(q.key, query) || this.match(q.label, query),
      this.questionTree.value.initializedQuestionData,
    );
  }
}

/**
 * Returns an instance element child for a given group element / question.
 *
 * @param groupQuestion
 * @param instanceToCheck
 */
export function getInstanceElementFromGroupElement(groupQuestion: JSONQuestion, instanceToCheck: string) {
  for (const referencedTableChildElement of groupQuestion.children) {
    if (referencedTableChildElement.instance === instanceToCheck) {
      return referencedTableChildElement;
    }
  }
  return null;
}

/**
 * Checks a given instance to see if another reference table is also referencing this instance (in its answer).
 *
 * @param instanceIdToSearchFor
 * @param sourceTableKey
 * @param excludeReferenceTableKey
 * @param rootQuestionTree
 * @param answers
 */
export function instanceUsedByOtherReferenceTables(
  instanceIdToSearchFor: string,
  sourceTableKey: string,
  excludeReferenceTableKey: string,
  answers: JSONObject,
  rootQuestionTree: JSONQuestion,
) {
  const foundReferenceTables = filter(
    (q: JSONQuestion) =>
      q.type === QuestionType.REFERENCE_TABLE_GIG &&
      q.optionsAnswerKey === sourceTableKey &&
      q.key !== excludeReferenceTableKey,
    rootQuestionTree,
  );
  for (const foundReferenceTable of foundReferenceTables) {
    const answerPath = foundReferenceTable.key.split(".");
    const answer = pathOr([], answerPath, answers);
    return answer.indexOf(instanceIdToSearchFor) !== -1;
  }
}

export function findReferenceTables(
  instanceIdToSearchFor: string,
  sourceTableKey: string,
  initializedQuestionTree: JSONQuestion,
  answers: JSONObject,
) {
  const foundReferenceTables = filter(
    (q: JSONQuestion) =>
      (q.type === QuestionType.REFERENCE_TABLE_GIG || q.type === QuestionType.REFERENCE_TABLE_WITHOUT_SELECTION) &&
      q.optionsAnswerKey === sourceTableKey,
    initializedQuestionTree,
  );
  const returnRefTables: Array<JSONQuestion> = [];
  for (const foundReferenceTable of foundReferenceTables) {
    const answerPath = foundReferenceTable.key.split(".");
    const answer = pathOr([], answerPath, answers);
    if (answer.indexOf(instanceIdToSearchFor) !== -1) {
      returnRefTables.push(foundReferenceTable);
    }
  }

  return returnRefTables;
}

/**
 * Returns a list of all instances referenced by child reference tables so that we remove those instances when we remove
 * a parent instance. Each referenced instance is checked against any other reference tables in the tree that reference
 * the same source table and ensure they aren't referencing any of the same instances (we don't want to remove any
 * rows from the source table that still have references from other reference tables).
 *
 * @param instanceQuestions
 * @param answers
 * @param rootQuestionTree
 */
export function findChildGigInstanceKeys(
  instanceQuestions: JSONQuestion,
  answers: JSONObject,
  rootQuestionTree: JSONQuestion,
) {
  let childInstanceKeysToRemove = [];
  const foundQs = filter((q: JSONQuestion) => q.type === QuestionType.REFERENCE_TABLE_GIG, instanceQuestions);
  for (const foundQ of foundQs) {
    if (foundQ.readOnly) {
      continue;
    }
    const answerPath = foundQ.key.split(".");
    const answer = pathOr([], answerPath, answers);
    const currentReferencedTableKey = pathOr("", ["optionsAnswerKey"], foundQ);
    const referencedTable = findNearestNeighbor((q) => q.key === currentReferencedTableKey, instanceQuestions);
    for (const currentAnswer of answer) {
      const instanceElement = getInstanceElementFromGroupElement(referencedTable, currentAnswer);
      if (!instanceElement) {
        continue;
      }
      const anotherTableIsUsingInstance = instanceUsedByOtherReferenceTables(
        currentAnswer,
        currentReferencedTableKey,
        foundQ.key,
        answers,
        rootQuestionTree,
      );
      if (!anotherTableIsUsingInstance) {
        childInstanceKeysToRemove.push(instanceElement.key);
      }
    }
  }

  return childInstanceKeysToRemove;
}

/**
 * Get the post code mask based on the ISO 2-letter country code
 *
 * @param countryCode -- Not CountryCode enum because we don't need enum for all countries
 */
export function getAddressPostalMask(countryCode: string): string[] | ((value: string) => string) | undefined {
  // UK is special
  if (countryCode === "GB") {
    return (value: string) => {
      if (value.length > 1 && value.substring(0, 2).match(/[a-zA-z]{2}/i)) {
        if (value.length > 3 && value[3].match(/[a-zA-Z]/i)) {
          return "UU#U #UU";
        } else {
          if (value.length > 5 && value[5].match(/[0-9]/i)) {
            return "UU## #UU";
          } else {
            return "UU# #UU";
          }
        }
      } else {
        if (value.length > 2 && value[2].match(/[a-zA-Z]/i)) {
          return "U#U #UU";
        } else {
          if (value.length > 4 && value[4].match(/[0-9]/i)) {
            return "U## #UU";
          } else {
            return "U# #UU";
          }
        }
      }
    };
  }
  if (postalMasks[countryCode] && !!postalMasks[countryCode].format) {
    return postalMasks[countryCode].format.split(",");
  }
}

/**
 * Brought over from QuestionTable, might be useful elsewhere
 *
 * @param columnType
 * @param columnVisible
 * @param value
 * @param questionData
 * @param instanceData
 * @param connectionDataLite
 */
export async function getFormattedValueForTable(
  columnType: TableColumnType | string,
  columnVisible: boolean,
  value: any,
  questionData,
  instanceData,
  connectionDataLite: ConnectionDataLite,
) {
  let formattedValue = value;
  if (!columnVisible) {
    formattedValue = "";
  } else if (columnType === TableColumnType.LOOKUP) {
    const options = await getOptionsAsync(questionData, connectionDataLite, getCurrentUser().locale);
    // @ts-ignore, I don't know this typing but it's blowing up stuff
    formattedValue = translateMultiValue(options, value, instanceData);
  } else if (columnType === TableColumnType.FILE) {
    formattedValue = translateFilesWithLinks(value);
  } else if (columnType === TableColumnType.DATE) {
    formattedValue = formatDate(value);
  } else if (columnType === TableColumnType.DATE_TIME) {
    formattedValue = formatDateTime(value);
  } else if (columnType === TableColumnType.USER_SELECT) {
    const users = await getUserInputOptions({
      questionData: questionData,
      suppressEmailAppend: true,
      defaultUserIds: Array.isArray(value) ? value : [value],
    });
    // @ts-ignore, no idea
    const indexedUsers = indexBy(prop("value"), users);
    formattedValue = indexedUsers[value];
  } else {
    formattedValue = getTextFromLocaleObj(value, getCurrentUser().locale, true);
  }
  return formattedValue;
}
