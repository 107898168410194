import {cloneDeep, get, set} from "lodash";
import {any, contains, equals, findIndex, indexBy, path, pickBy, reject, uniq} from "ramda";
import {
  checkRequired,
  cloneTreeWithParents,
  filter,
  filterAsync,
  find,
  isMultipleGroup,
  isQuestion,
  processTreeTopDown,
  processTreeTopDownAsync,
  processTreeWithBranchFiltering,
} from ".";
import {
  GroupAnswer,
  isGroupAnswer,
  processGroupInstances,
  processGroups,
  translateNewGroupReferences,
} from "../answers";
import {AgreementStatus} from "../api/adobe-sign-external";
import {UploadedFile} from "../api/document";
import {ListAnswerType} from "../api/lists";
import {GroupSubType, GroupType, Internal, QuestionType, TopicReviewAnswerKeys} from "../enums";
import {BooleanAnswers, CampaignAnswerKeys, DocuSignStatus, MagicAnswerKeys} from "../enums/answers";
import {AsyncExpressionFunctions, ComputeProps, COUNTERPARTY, isCounterpartyKey, Question} from "../props";
import {AsyncExpressionEvaluator, ExpressionResult} from "../rules/expression";
import {JSONObject, JSONQuestion, JSONValue, Profiler} from "../utils";
import {INSTANCE_ORDER} from "../utils/constants";
import {nonDeletedInstances} from "./group-util";

export interface ProfileState {
  questions: JSONQuestion;
  answers: JSONObject;
  theirAnswers?: JSONObject;
}

export interface ProfileStateWithSwappedIds extends ProfileState {
  swappedIds: JSONObject;
}

export interface MasterInstanceMap {
  [groupKey: string]: {
    answerKeys: Set<string>;
    parentGroupKey?: string;
    parentInstanceMap?: {
      [instanceId: string]: string;
    };
    gigReferenceGroupKey?: string;
  };
}

export class ProfileGroup {
  // Assumes from has been normalized (see below)
  public static findMissingRequiredQuestions(within: JSONQuestion, answers: JSONObject): JSONQuestion[] {
    const missing: JSONQuestion[] = [];
    processTreeWithBranchFiltering(
      (q: JSONQuestion) => !!q.visible,
      (q: JSONQuestion) => {
        if (checkRequired(q, answers)) {
          missing.push(q);
        }
      },
      within,
    );
    return missing;
  }

  public static findUnsignedDocuments(within: JSONQuestion, answers: JSONObject): JSONQuestion[] {
    const missing: JSONQuestion[] = [];
    processTreeTopDown((q: JSONQuestion) => {
      if (ProfileGroup.checkUnsignedDocument(q, answers)) {
        missing.push(q);
      }
    })(within);
    return missing;
  }

  public static async swapInstanceIds(
    instanceIdMap: Record<string, Record<string, string>>,
    originalQuestions: JSONQuestion,
    {questions, answers}: ProfileState,
    asyncExpressionFunctions?: AsyncExpressionFunctions,
    delayAdd: boolean = false,
  ): Promise<ProfileStateWithSwappedIds & {addToTree?: () => void}> {
    const swappedIds: JSONObject = {};
    const addGroups: Array<() => void> = [];

    const masterInstanceMap = this.getMasterInstanceMap(originalQuestions, answers);
    // The brutal inefficiency here is now obvious, where it used to be separated by several layers of code. Come
    // back and make this better, please.
    for (const groupKey of Object.keys(instanceIdMap)) {
      const groupData = instanceIdMap[groupKey];
      for (const oldInstanceId of Object.keys(groupData)) {
        const newInstanceId = groupData[oldInstanceId];
        let addToTree: () => void;
        ({questions, addToTree} = await this.swapInstanceIdQuestions(
          groupKey,
          oldInstanceId,
          newInstanceId,
          originalQuestions,
          questions,
          masterInstanceMap,
          asyncExpressionFunctions,
        ));
        if (delayAdd) {
          addGroups.push(addToTree);
        } else {
          addToTree();
        }
        answers = this.swapInstanceIdAnswers(groupKey, oldInstanceId, newInstanceId, answers);
        swappedIds[oldInstanceId] = newInstanceId;
      }
    }
    this.swapGroupReferences(instanceIdMap, questions, answers);

    return {
      questions,
      answers,
      swappedIds,
      addToTree: delayAdd ? () => addGroups.forEach((ag) => ag()) : undefined,
    };
  }

  public static swapInstanceIdAnswers(
    groupKey: string,
    oldInstanceId: string,
    newInstanceId: string,
    answers: JSONObject,
  ) {
    answers = cloneDeep(answers);
    const group: JSONObject | undefined = answers[groupKey] as JSONObject;
    if (group) {
      if (group[INSTANCE_ORDER]) {
        const index = (group[INSTANCE_ORDER] as string[]).indexOf(oldInstanceId);
        if (index !== -1) {
          (group[INSTANCE_ORDER] as string[])[index] = newInstanceId;
        }
      }
      group[newInstanceId] = group[oldInstanceId];
      delete group[oldInstanceId];
    }
    return answers;
  }

  public static makeInstancePrimary(
    groupKey: string,
    instanceId: string,
    {questions, answers}: ProfileState,
  ): ProfileState {
    // Answers
    {
      const group = answers[groupKey];
      if (!group) {
        return {questions, answers};
      }
      const instanceOrder = group[INSTANCE_ORDER] as string[];
      const currentIndex = instanceOrder.indexOf(instanceId);
      if (currentIndex < 0) {
        return {questions, answers};
      }
      instanceOrder.splice(currentIndex, 1);
      instanceOrder.unshift(instanceId);
    }

    // Questions
    const groupQuestions = ProfileGroup.findGroups(groupKey, questions);
    for (const groupQuestion of groupQuestions) {
      if (groupQuestion && groupQuestion.children) {
        const currentIndex = findIndex((c) => (c as JSONObject).instance === instanceId, groupQuestion.children);
        if (currentIndex > -1) {
          const child = groupQuestion.children.splice(currentIndex, 1);
          groupQuestion.children.unshift(...child);
        }
      }
    }

    return {questions, answers};
  }

  public static async removeInstance(
    groupKey: string,
    instanceId: string,
    originalQuestions: JSONQuestion,
    {questions, answers}: ProfileState,
    noClone?: boolean,
    asyncExpressionFunctions?: AsyncExpressionFunctions,
    delayAdd?: boolean,
  ): Promise<ProfileState & {addToTree?: () => void}> {
    let state = {
      questions: this.removeInstanceQuestions(groupKey, instanceId, questions, noClone),
      answers: this.removeInstanceAnswers(groupKey, instanceId, answers),
      addToTree: () => {
        // no-op
      },
    } as ProfileState & {addToTree?: () => void};
    const questionGroup = ProfileGroup.findGroups(groupKey, state.questions)[0];
    if (await this.isEmptyRequiredGroup(state.answers, asyncExpressionFunctions)(questionGroup)) {
      state = await this.addInstance(
        groupKey,
        "default_" + groupKey,
        originalQuestions,
        state,
        true,
        undefined,
        undefined,
        noClone,
        asyncExpressionFunctions,
        delayAdd,
      );
    }
    return state;
  }

  public static removeInstanceQuestions(
    groupKey: string,
    instanceId: string,
    questions: JSONQuestion,
    noClone?: boolean,
  ) {
    questions = noClone ? questions : cloneTreeWithParents(questions);
    const groupQuestions = ProfileGroup.findGroups(groupKey, questions);
    groupQuestions.forEach((groupQuestion) => {
      if (groupQuestion && groupQuestion.children) {
        groupQuestion.children = reject<JSONQuestion>((c) => equals(instanceId, (c as JSONObject).instance))(
          groupQuestion.children,
        );
      }
    });
    return questions;
  }

  public static removeInstanceAnswers(groupKey: string, instanceId: string, answers: JSONObject) {
    answers = cloneDeep(answers);
    const group = answers[groupKey];
    if (group) {
      if (group[instanceId]?.[Internal.NEW_INSTANCE]) {
        group[INSTANCE_ORDER] = reject(equals(instanceId))(group[INSTANCE_ORDER]);
        delete group[instanceId];
      } else if (group[instanceId]) {
        group[instanceId][Internal.DELETED] = true;
      }
    }
    return answers;
  }

  public static async addInstance(
    groupKey: string,
    instanceId: string,
    originalQuestions: JSONQuestion,
    profileState: ProfileState,
    isDefault: boolean = false,
    parentInstanceId?: string,
    masterInstanceMap?: MasterInstanceMap,
    noClone?: boolean,
    asyncExpressionFunctions?: AsyncExpressionFunctions,
    delayAdd: boolean = false,
  ): Promise<ProfileState & {addToTree?: () => void}> {
    if (!masterInstanceMap) {
      masterInstanceMap = this.getMasterInstanceMap(originalQuestions, profileState.answers);
    }
    if (parentInstanceId && masterInstanceMap[groupKey]?.parentInstanceMap) {
      masterInstanceMap[groupKey]!.parentInstanceMap![instanceId] = parentInstanceId;
    }
    const {questions, defaults, addToTree} = await this.addInstanceQuestions(
      groupKey,
      instanceId,
      originalQuestions,
      profileState,
      masterInstanceMap,
      noClone,
      asyncExpressionFunctions,
    );
    Profiler.ping(() => `addInstanceQuestions ${groupKey}`);
    if (isDefault) {
      defaults[Internal.FORCED_REQUIRED_INSTANCE] = true;
    }
    defaults[Internal.NEW_INSTANCE] = true;
    const answers = this.addInstanceAnswers(groupKey, instanceId, profileState.answers, defaults, noClone);
    if (!delayAdd) {
      addToTree();
    }
    return {questions, answers, addToTree: delayAdd ? addToTree : undefined};
  }

  public static addInstanceAnswers(
    groupKey: string,
    instanceId: string,
    answers: JSONObject,
    groupInstance: JSONObject = {},
    noClone?: boolean,
  ): JSONObject {
    if (!noClone) {
      answers = cloneDeep(answers);
    }
    this.addInstanceAnswersMutate(groupKey, instanceId, answers, groupInstance, noClone);
    return answers;
  }

  public static findGroupInstanceQuestions(instanceKey: string, questions: JSONQuestion): JSONQuestion[] {
    const instances: JSONQuestion[] = [];
    const [groupKey, instanceId] = instanceKey.split(".");
    const groupQuestions = ProfileGroup.findGroups(groupKey, questions);
    for (const groupQuestion of groupQuestions) {
      if (groupQuestion && groupQuestion.children) {
        const currentIndex = findIndex((c) => (c as JSONObject).instance === instanceId, groupQuestion.children);
        if (currentIndex > -1) {
          instances.push(groupQuestion.children[currentIndex]);
        }
      }
    }
    return instances;
  }

  public static async normalizeGroupQuestionsAndAnswers(
    questionData: JSONQuestion,
    answerData: JSONObject,
    theirAnswers?: JSONObject,
    buildRequiredGroupInstances: boolean = true,
    ensureInstanceOrders: boolean = true,
    asyncExpressionFunctions?: AsyncExpressionFunctions,
  ): Promise<{questionData: JSONQuestion; answers: JSONObject}> {
    let questions = cloneTreeWithParents(questionData);
    Profiler.ping("normalizeGQA - cloneTree");
    let answers = answerData;
    const masterInstanceMap = this.getMasterInstanceMap(questions, answers);
    this.recursiveNormalized([questions], answers, ensureInstanceOrders, masterInstanceMap);
    Profiler.ping("normalizeGQA - recursiveNormalized");
    if (buildRequiredGroupInstances) {
      const emptyRequiredGroups = await this.findEmptyRequiredGroups(questions, answers, asyncExpressionFunctions);
      const keys = uniq(emptyRequiredGroups.map((g) => g.key));
      for (const key of keys) {
        ({questions, answers} = await this.addInstance(
          key,
          `default_${key}`,
          questionData,
          {questions, answers, theirAnswers},
          true,
          undefined,
          masterInstanceMap,
          true,
          asyncExpressionFunctions,
        ));
      }
      Profiler.ping("normalizeGQA - emptyRequiredGroups");
    }
    return {questionData: questions, answers};
  }

  public static async setDefaults(
    groupInstance: JSONQuestion,
    profileState: {answers: JSONObject; theirAnswers?: JSONObject},
    defaults: JSONObject,
    asyncExpressionFunctions?: AsyncExpressionFunctions,
  ) {
    let changes = true;
    let count = 0;
    const answersOverlay = {};
    set(answersOverlay, groupInstance.key, defaults);
    while (changes && count < 10) {
      changes = false;
      count++;
      await processTreeTopDownAsync(async (q: JSONQuestion) => {
        if (q.default) {
          let decode = asyncExpressionFunctions?.decode;
          if (decode) {
            const originalDecode = decode;
            decode = async (...args: ExpressionResult) => await originalDecode(args[0], args[1], args[2], q);
          }
          const e = new AsyncExpressionEvaluator(q.default, {
            ...asyncExpressionFunctions,
            decode,
            skipFormatting: true,
          });
          const baseKey: string = q.key.split(".").pop()!;
          let answers = profileState.answers;
          if (any(isCounterpartyKey, e.answerKeys)) {
            answers = {...answers, [COUNTERPARTY]: profileState.theirAnswers};
          }
          const value = (await e.evaluate(answersOverlay, answers)) as JSONValue;
          if (value === undefined) {
            return; // Undefined results must not overwrite, see also ComputeProps.applyDefault.
          }
          if (!equals(defaults[baseKey], value)) {
            changes = true;
          }
          defaults[baseKey] = value;
          set(answersOverlay, q.key, value);
        }
      }, groupInstance);
    }
  }

  public static checkReferences(
    questions: JSONQuestion,
    answers: JSONObject,
    groupKey: string,
    instanceId: string,
  ): boolean {
    return Boolean(
      find((q: JSONQuestion): boolean => {
        const split = q.optionsAnswerKey && q.optionsAnswerKey.split(".");
        if (!split || split[0] !== groupKey) {
          return false;
        }
        // readOnly reference tables do not count as a reference.
        if (q.readOnly) {
          return false;
        }
        const answer = path(q.key.split("."), answers);
        return answer === instanceId || (Array.isArray(answer) && contains(instanceId, answer));
      }, questions),
    );
  }

  public static clearNewInstances = (answers: JSONObject) => {
    processGroups((group) => {
      processGroupInstances((instance) => delete instance[Internal.NEW_INSTANCE], group);
    }, answers);
  };

  public static getMasterInstanceMap(questions: Question, answers: JSONObject): MasterInstanceMap {
    const groupMap: {
      [groupKey: string]: {
        answerKeys: Set<string>;
        parentGroupKey?: string;
        gigReferenceGroupKey?: string;
        parentGroupChildrenKey?: string;
      };
    } = {};
    this.buildGroupMap(questions, groupMap);
    const masterInstanceMap: MasterInstanceMap = {};
    for (const groupKey of Object.keys(groupMap)) {
      masterInstanceMap[groupKey] = {
        answerKeys: groupMap[groupKey].answerKeys,
        parentGroupKey: groupMap[groupKey].parentGroupKey,
        gigReferenceGroupKey: groupMap[groupKey].parentGroupChildrenKey,
      };
      if (groupMap[groupKey].parentGroupKey) {
        masterInstanceMap[groupKey].parentInstanceMap = this.buildParentInstanceMap(
          groupMap[groupKey].parentGroupKey!,
          groupMap[groupKey].parentGroupChildrenKey!,
          answers,
        );
      }
    }
    return masterInstanceMap;
  }

  public static getEvaluationContext(
    answers: JSONObject,
    groupKey: string | undefined,
    instanceId: string | undefined,
    masterInstanceMap: MasterInstanceMap | undefined,
  ): JSONObject[] {
    const result: JSONObject[] = [];
    let originalGroup = true;
    if (instanceId) {
      while (groupKey && instanceId) {
        const groupAnswer = path(groupKey.split("."), answers) as JSONObject;
        const instance = groupAnswer?.[instanceId] as JSONObject;
        if (instance) {
          result.push(instance);
        }
        if (originalGroup) {
          result.push({[MagicAnswerKeys.INSTANCE_ID]: instanceId});
        }

        originalGroup = false;
        instanceId = masterInstanceMap?.[groupKey]?.parentInstanceMap?.[instanceId];
        groupKey = masterInstanceMap?.[groupKey]?.parentGroupKey;
      }
    }
    result.push(answers);
    return result;
  }

  public static buildAnswerKeyMap(
    masterInstanceMap: MasterInstanceMap,
    groupKey: string,
    instanceId: string,
  ): Record<string, {groupKey: string; instanceId: string}> {
    const result: Record<string, {groupKey: string; instanceId: string}> = {};
    const groupMap: {answerKeys: Set<string>; parentGroupKey?: string; parentInstanceMap?: {[p: string]: string}} =
      masterInstanceMap[groupKey];
    if (groupMap) {
      for (const k of [
        ...groupMap.answerKeys,
        TopicReviewAnswerKeys.REVIEW_END_DATE,
        TopicReviewAnswerKeys.REVIEW_START_DATE,
        MagicAnswerKeys.FIRST,
        CampaignAnswerKeys.CAMPAIGN_ID,
      ]) {
        result[k] = {groupKey, instanceId};
      }

      if (groupMap.parentGroupKey) {
        const parentInstanceId = groupMap.parentInstanceMap?.[instanceId];
        if (parentInstanceId) {
          Object.assign(result, this.buildAnswerKeyMap(masterInstanceMap, groupMap.parentGroupKey, parentInstanceId));
        }
      }
    }
    return result;
  }

  private static async swapInstanceIdQuestions(
    groupKey: string,
    oldInstanceId: string,
    newInstanceId: string,
    originalQuestions: JSONQuestion,
    questions: JSONQuestion,
    masterInstanceMap: MasterInstanceMap,
    asyncExpressionFunctions?: AsyncExpressionFunctions,
  ): Promise<{questions: JSONQuestion; defaults: JSONObject; addToTree: () => void}> {
    return await this.addInstanceQuestions(
      groupKey,
      newInstanceId,
      originalQuestions,
      {
        questions: this.removeInstanceQuestions(groupKey, oldInstanceId, questions, true),
        answers: {},
      },
      masterInstanceMap,
      true,
      asyncExpressionFunctions,
    );
  }

  private static async findEmptyRequiredGroups(
    questions: JSONQuestion,
    answers: JSONObject,
    asyncExpressionFunctions?: AsyncExpressionFunctions,
  ): Promise<JSONQuestion[]> {
    return await filterAsync(this.isEmptyRequiredGroup(answers, asyncExpressionFunctions), questions);
  }

  private static isEmptyRequiredGroup(
    answers: JSONObject,
    asyncExpressionFunctions?: AsyncExpressionFunctions,
  ): (q: JSONQuestion) => Promise<boolean> {
    return async (q) => {
      if (
        !q ||
        !isMultipleGroup(q) ||
        q.subType === GroupSubType.REVIEW ||
        nonDeletedInstances(answers[q.key] as GroupAnswer).length > 0
      ) {
        return false;
      }
      let required = q.required;
      if (typeof required === "object") {
        required = Boolean(await new AsyncExpressionEvaluator(required, asyncExpressionFunctions).evaluate(answers));
      }
      return required;
    };
  }

  private static async addInstanceQuestions(
    groupKey: string,
    instanceId: string,
    originalQuestions: JSONQuestion,
    profileState: ProfileState,
    masterInstanceMap: MasterInstanceMap,
    noClone?: boolean,
    asyncExpressionFunctions?: AsyncExpressionFunctions,
  ): Promise<{questions: JSONQuestion; defaults: JSONObject; addToTree: () => void}> {
    const questions = noClone ? profileState.questions : cloneTreeWithParents(profileState.questions);
    const defaults = {};
    if (this.isNewFirstInstance(groupKey, profileState.answers)) {
      defaults[MagicAnswerKeys.FIRST] = BooleanAnswers.YES;
    }
    const groupQuestions = ProfileGroup.findGroups(groupKey, questions);
    const originalGroupQuestions = indexBy((q) => q._id!, ProfileGroup.findGroups(groupKey, originalQuestions));
    const groupInstancesToAdd: Array<{groupInstance: JSONQuestion; parent: JSONQuestion}> = [];
    for (const groupQuestion of groupQuestions) {
      const originalGroupQuestion = originalGroupQuestions[groupQuestion._id!];
      if (groupQuestion && groupQuestion.children && originalGroupQuestion && originalGroupQuestion.children) {
        const groupInstance = ProfileGroup.createGroupInstance(
          groupKey,
          instanceId,
          originalGroupQuestion,
          masterInstanceMap,
        );
        this.recursiveNormalized([groupInstance], {}, true, masterInstanceMap);
        groupInstancesToAdd.push({groupInstance, parent: groupQuestion});
        await this.setDefaults(groupInstance, profileState, defaults, asyncExpressionFunctions);
      }
    }

    const addToTree = () => {
      for (const {groupInstance, parent} of groupInstancesToAdd) {
        groupInstance.parent = parent;
        parent.children!.push(groupInstance);
      }
    };
    return {questions, defaults, addToTree};
  }

  private static addInstanceAnswersMutate(
    groupKey: string,
    instanceId: string,
    answers: JSONObject,
    groupInstance: JSONObject,
    noClone?: boolean,
  ): void {
    const group = this.ensureInstanceOrder(groupKey, answers);
    if (!contains(instanceId, group[INSTANCE_ORDER])) {
      if (noClone) {
        set(group[INSTANCE_ORDER], group[INSTANCE_ORDER].length, instanceId);
      } else {
        group[INSTANCE_ORDER].push(instanceId);
      }
    }
    if (group[INSTANCE_ORDER].length === 1) {
      // we are adding this instances and it's the only instance
      groupInstance[MagicAnswerKeys.FIRST] = BooleanAnswers.YES;
    } else {
      const notDeleted: JSONObject = pickBy((item) => !item[Internal.DELETED], group);
      if (Object.keys(notDeleted).length === 1) {
        groupInstance[MagicAnswerKeys.FIRST] = BooleanAnswers.YES;
      }
    }
    groupInstance = Object.assign(group[instanceId] || {}, groupInstance);
    group[instanceId] = groupInstance;
  }

  private static findGroups(groupKey: string, question: JSONQuestion): JSONQuestion[] {
    return filter(this.groupFinder(groupKey), question);
  }

  private static groupFinder(groupKey: string): (question: JSONQuestion) => boolean {
    return (question) => isMultipleGroup(question) && question.key === groupKey;
  }

  private static setInstanceId(
    groupKey: string,
    instanceId: string,
    question: JSONQuestion,
    masterInstanceMap: MasterInstanceMap,
    answerKeyMap?: Record<string, {groupKey: string; instanceId: string}>,
  ) {
    if (question.type === GroupType.GROUP_INSTANCE) {
      question.key = groupKey + "." + instanceId;
      question.instance = instanceId;
      answerKeyMap = this.buildAnswerKeyMap(masterInstanceMap, groupKey, instanceId);
    } else {
      const baseKey = question.key.split(".").pop();
      question.key = [groupKey, instanceId, baseKey].join(".");
    }
    ComputeProps.setGroupDependencies(answerKeyMap!, groupKey, instanceId, question);

    if (question.list?.answerPath?.length) {
      question.list = {
        ...question.list,
        answerPath: question.list.answerPath.map((ansPart) => {
          if (ansPart.type === ListAnswerType.Key && answerKeyMap && ansPart.value in answerKeyMap) {
            const {groupKey: gk, instanceId: inst} = answerKeyMap[ansPart.value];
            return {
              ...ansPart,
              value: [gk, inst, ansPart.value].join("."),
            };
          }
          return ansPart;
        }),
      };
    }

    if (question.children) {
      question.children.forEach((child) =>
        ProfileGroup.setInstanceId(groupKey, instanceId, child, masterInstanceMap, answerKeyMap),
      );
    }
    if (question.columns) {
      question.columns.forEach((column) =>
        ProfileGroup.setInstanceId(groupKey, instanceId, column as JSONQuestion, masterInstanceMap, answerKeyMap),
      );
    }
  }

  private static createGroupInstance(
    groupKey: string,
    instanceId: string,
    template: JSONQuestion,
    masterInstanceMap: MasterInstanceMap,
  ): JSONQuestion {
    const groupInstance: JSONQuestion = {
      _id: groupKey + "." + instanceId,
      key: groupKey + "." + instanceId,
      instance: instanceId,
      label: template.label,
      hintText: template.hintText,
      type: GroupType.GROUP_INSTANCE,
      children: template.children && template.children.map((child) => cloneTreeWithParents(child)),
      columns: template.columns && template.columns.map((c) => ({...c})),
      required: false,
      visible: true,
      filterVisible: true,
      internalUse: template.internalUse,
      counterpartyCanEditAnswer: template.counterpartyCanEditAnswer,
      actions: template.actions && template.actions.map((action) => ({...action})),
      disallowDeleteWhen: template.disallowDeleteWhen,
    };
    ProfileGroup.setInstanceId(groupKey, instanceId, groupInstance, masterInstanceMap);
    return groupInstance;
  }

  private static recursiveNormalized(
    currentProfileData: JSONQuestion[],
    answerData: JSONObject,
    ensureInstanceOrders: boolean,
    masterInstanceMap: MasterInstanceMap,
  ) {
    for (const currentChild of currentProfileData) {
      this.normalizeMultipleGroupCheck(currentChild, answerData, ensureInstanceOrders, masterInstanceMap);

      if (currentChild.children) {
        this.recursiveNormalized(currentChild.children, answerData, ensureInstanceOrders, masterInstanceMap);
        currentChild.children.forEach((child) => (child.parent = currentChild));
      }
    }
  }

  private static normalizeMultipleGroupCheck(
    currentChild: JSONQuestion,
    answerData: JSONObject,
    ensureInstanceOrders: boolean,
    masterInstanceMap: MasterInstanceMap,
  ) {
    // Check for Multiple Group Type
    if (isMultipleGroup(currentChild) && currentChild.children) {
      const groupKey: string = currentChild.key;
      // ensureInstanceOrder creates a default instance, which allows this.createGroupInstance
      // to update the JSONQuestion.key to have the full path, which we still want when normalizing.
      // This will create the empty instance but then remove it before returning
      const removeEmptyGroupData = !ensureInstanceOrders && !answerData[groupKey];
      const group = this.ensureInstanceOrder(groupKey, answerData);

      // Go through Instances
      currentChild.children = nonDeletedInstances(group).map((instanceId) =>
        this.createGroupInstance(groupKey, instanceId.toString(), currentChild, masterInstanceMap),
      );

      if (removeEmptyGroupData) {
        delete answerData[groupKey];
      }
    }
  }

  private static ensureInstanceOrder(groupKey: string, answers: JSONObject): JSONObject & {instanceOrder: string[]} {
    let group = answers[groupKey] as JSONObject | undefined;
    if (!group || Array.isArray(group)) {
      group = {};
      answers[groupKey] = group;
    }
    if (!group[INSTANCE_ORDER]) {
      group[INSTANCE_ORDER] = [];
    }
    return group as JSONObject & {instanceOrder: string[]};
  }

  private static validatedMagicKeys = (k: string): string[] => [
    `${k}${Internal.VALIDATED_BY}`,
    `${k}${Internal.VALIDATED_DATE}`,
    `${k}${Internal.VALIDATION_STATUS}`,
    `${k}${Internal.VALIDATION_NOTE}`,
  ];

  private static swapGroupReferences(instanceIdMap: Record<string, Record<string, string>>, questions, answers) {
    const references: Record<string, string> = {};
    processTreeTopDown<JSONQuestion>((question) => {
      if (question.optionsAnswerKey) {
        const groupKey = question.optionsAnswerKey.split(".")[0];
        if (groupKey in instanceIdMap) {
          const baseKey: string = question.key.split(".").pop()!;
          references[baseKey] = groupKey;
        }
      }
    })(questions);
    translateNewGroupReferences(references, instanceIdMap, answers);
  }

  private static buildGroupMap(
    question: Question,
    groupMap: {[p: string]: {answerKeys: Set<string>; parentGroupKey?: string; parentGroupChildrenKey?: string}},
    groupKey?: string,
  ) {
    if (isQuestion(question) && groupKey) {
      const baseKey = question.key.split(".").pop()!;
      groupMap[groupKey].answerKeys.add(baseKey);
      if (question.externalValidation) {
        for (const key of ProfileGroup.validatedMagicKeys(baseKey)) {
          groupMap[groupKey].answerKeys.add(key);
        }
      }
      groupMap[groupKey].answerKeys.add(`${baseKey}${Internal.SCORE_OVERRIDE}`);
      groupMap[groupKey].answerKeys.add(`${baseKey}${Internal.UNVERIFIED}`);

      if (question.type === QuestionType.REFERENCE_TABLE_GIG) {
        const childGroupKey = question.optionsAnswerKey!;
        groupMap[childGroupKey] = groupMap[childGroupKey] || {
          answerKeys: new Set<string>(),
        };
        groupMap[childGroupKey].parentGroupKey = groupKey;
        groupMap[childGroupKey].parentGroupChildrenKey = question.key;
      }
    }

    if (isMultipleGroup(question)) {
      groupKey = question.key;
      groupMap[groupKey] = groupMap[groupKey] || {
        answerKeys: new Set<string>(question.hiddenKeys),
      };
    }

    if (question.children) {
      question.children.forEach((c) => this.buildGroupMap(c, groupMap, groupKey));
    }

    if (question.columns && groupKey) {
      for (const column of question.columns) {
        groupMap[groupKey].answerKeys.add(column.key as string);
      }
    }
  }

  private static buildParentInstanceMap(
    parentGroupKey: string,
    parentGroupChildrenKey: string,
    answers: JSONObject,
  ): {[instanceId: string]: string} {
    const parentInstanceMap: {[instanceId: string]: string} = {};
    for (const parentInstanceId of answers[parentGroupKey]?.[INSTANCE_ORDER] || []) {
      const children = answers[parentGroupKey]?.[parentInstanceId]?.[parentGroupChildrenKey];
      if (children && Array.isArray(children)) {
        for (const child of children) {
          parentInstanceMap[child] = parentInstanceId;
        }
      }
    }
    return parentInstanceMap;
  }

  private static checkUnsignedDocument = (question: JSONQuestion, answers: JSONObject): boolean => {
    if (
      (question.type !== QuestionType.FILE &&
        question.type !== QuestionType.FILE_OR_URL &&
        question.type !== QuestionType.URL) ||
      (!question.enableAdobeSign && !question.enableDocuSign) ||
      !question.visible
    ) {
      return false;
    }

    const answer = get(answers, question.key);
    if (!answer || !Array.isArray(answer)) {
      return false;
    }

    const fileAnswer = answer as unknown as UploadedFile[];
    for (const file of fileAnswer) {
      if (
        (question.enableAdobeSign &&
          file.adobeSignDetails &&
          file.adobeSignDetails.status !== AgreementStatus.SIGNED) ||
        (question.enableDocuSign && file.docusignDetails && file.docusignDetails.status !== DocuSignStatus.COMPLETED)
      ) {
        return true;
      }
    }
    return false;
  };

  private static isNewFirstInstance(groupKey: string, answers: JSONObject) {
    const groupAnswer = answers[groupKey];
    return !isGroupAnswer(groupAnswer) || nonDeletedInstances(groupAnswer).length === 0;
  }
}
